window.initPopover = (container) => {
    $(container + ' [data-bs-toggle="popover"]').each(function (index, element) {

        options = {
            sanitize: false,
            html: true,
        }

        let content = $(this).data('content');

        if (content) {
            options.content = $(content).html();
        }

        new bootstrap.Popover(element, options)
    });
}

document.addEventListener('DOMContentLoaded', function () {
    new bootstrap.Popover($('html'), {
        selector: '.ajax-popover',
        html: true,
        sanitize: false,
        trigger: 'focus',
        content: function () {
            let content = ($(this).data('content'));
            if (content) {
                return $(content).html();
            }
            return $(this).data('bs-content');
        }
    });
});

$(function () {
    initPopover('body');
});
